// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trusted-partners {
  margin: 6rem 0 !important;
}
.trusted-by-partners-wrapper h2 {
  font-size: 2.2rem;
  text-align: center;
}
.partner-logo-images {
  flex-wrap: wrap;
  column-gap: 12rem;
  justify-content: center;
  margin-top: 3rem;
  align-items: center;
  row-gap: 4rem;
  transition: 0.8s;
  transform: scale(0);
}

.section-animation .partner-logo-images {
  transform: scale(1);
}
@media (max-width: 768px) {
  .partner-logo-images {
    column-gap: 7rem;
    display: flex;
    gap: 40px;
  }
  .partner-logo-images img {
    width: 14rem;
  }
}
@media (max-width: 500px) {
  .partner-logo-images {
    justify-content: flex-start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/PartnerSection/partner.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,gBAAgB;IAChB,aAAa;IACb,SAAS;EACX;EACA;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".trusted-partners {\n  margin: 6rem 0 !important;\n}\n.trusted-by-partners-wrapper h2 {\n  font-size: 2.2rem;\n  text-align: center;\n}\n.partner-logo-images {\n  flex-wrap: wrap;\n  column-gap: 12rem;\n  justify-content: center;\n  margin-top: 3rem;\n  align-items: center;\n  row-gap: 4rem;\n  transition: 0.8s;\n  transform: scale(0);\n}\n\n.section-animation .partner-logo-images {\n  transform: scale(1);\n}\n@media (max-width: 768px) {\n  .partner-logo-images {\n    column-gap: 7rem;\n    display: flex;\n    gap: 40px;\n  }\n  .partner-logo-images img {\n    width: 14rem;\n  }\n}\n@media (max-width: 500px) {\n  .partner-logo-images {\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
